export default {


  getAllIDS(tree, path = []) {

    if (!Array.isArray(tree)) return []
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i]
      path.push(node.id)
      if (Array.isArray(node.children)) {
        this.getAllIDS(node.children, path)
      }
    }
    return path
  },




  // 判断是否小于9加0 日期调用
  add0(m) {
    return m < 10 ? '0' + m : m
  },
  format(sjc, type='ymdhmms') {

    if (!sjc) {
      return ''
    }

    let time = ''
    if (!!window.ActiveXObject || "ActiveXObject" in window) { //是IE
      if (typeof (sjc) == 'number') {
        time = new Date(sjc);
      } else if (typeof (sjc) == 'string') {
        time = new Date(sjc.replace(/-/g, "/"))
      }
    } else {
      time = new Date(sjc);
    }
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    // 年月日
    // return y+'-'+this.add0(m)+'-'+this.add0(d)
    // 年月日时分秒

    if (type == 'ymdhmms') {
      return y + '-' + this.add0(m) + '-' + this.add0(d) + ' ' + this.add0(h) + ':' + this.add0(mm) + ':' +
        this.add0(s);
    } else if (type == 'yymd') {
      return y + '-' + this.add0(m) + '-' + this.add0(d)
    }


  },




}